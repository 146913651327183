import Loader from 'react-loader-spinner'

const MyLoader = ({ loader, marginTop }) => {
    if (loader === false)
        return null
    return <div style={{marginTop: marginTop ? marginTop : 20, alignSelf: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={70}
            visible={true} />
    </div>
};

export default MyLoader