import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/b1.png';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import showToast from './util/toast'

class Contact extends Component {

    state = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        msg: '',
        loader: false,
    }

    sendEmail = (e) => {
        if(this.state.name === '' 
        || this.state.email === ''
        || this.state.phone === ''
        || this.state.msg === '' ){
            showToast('error', 'Fill the form')
            return
        }
        this.setState({ loader: true })
        e.preventDefault();    // This is important, i'm not sure why, but the email won't send without it
        emailjs.sendForm('gmail', 'template_mdhcc9i', e.target, '5yIURgbf9PsA9yLIR')
            .then((result) => {
                this.setState({ loader: false })
                showToast('success', 'Email Sent!')
                setTimeout(() => {
                    window.location.reload()
                }, 1500);
            });
    }

    render() {
        return (
            <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
                </div>
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Form onSubmit={this.sendEmail} className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10 }}>
                            <h5>
                                Send us a message we will respond asap!
                            </h5>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_name"
                                    style={{ fontSize: 18 }}
                                    type='text'
                                    placeholder='Name'
                                    value={this.state.name}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ name: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_email"
                                    style={{ fontSize: 18 }}
                                    type='email'
                                    placeholder='Email'
                                    value={this.state.email}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ email: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="from_phone"
                                    style={{ fontSize: 18 }}
                                    type='number'
                                    placeholder='Phone'
                                    value={this.state.phone}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ phone: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <Input
                                    name="message"
                                    style={{ fontSize: 18, minHeight: 200 }}
                                    type='textarea'
                                    placeholder='Your message....'
                                    value={this.state.msg}
                                    onChange={(event) => {
                                        console.log('change: ' + event.target.value);
                                        this.setState({ msg: event.target.value })
                                    }} />
                            </FormGroup>
                            <FormGroup style={{ marginTop: 15 }}>
                                <input
                                    type="submit"
                                    className='btn-block btn-dark'
                                    style={{ marginTop: 0, height: 40, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, borderRadius: 5 }}
                                    value="Send" />
                            </FormGroup>
                        </Form>
                    </div>
                    {this.state.loader ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                            <Loader
                                type="ThreeDots"
                                color="#20b4c6"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                        :
                        null
                    }
                </div>
                <div>
                    <ToastContainer />
                </div>
            </div>
        )
    }
}

export default Contact;