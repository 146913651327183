import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/b1.png';
import screen1 from '../assets/screen3.png';
import screen2 from '../assets/screen4.png';
import apple from '../assets/apple1.png'
import google from '../assets/google1.png';
import f from '../assets/background1.png';
import background_strip from '../assets/background_strip.png';
import gallery_welcome from '../assets/gallery_welcome.png';

class ArtGallery extends Component {

    state = {
        models: [],
        loader: true,
        screenWidth: 1000
    }

    getDimensions = () => {
        this.setState({ screenWidth: window.innerWidth });
    };

    componentDidMount() {
        this.loadData();
        this.getDimensions()
        window.addEventListener("resize", this.getDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions);
    }

    loadData = () => {
        this.setState({ loader: true })
        db.collection("dale_art_gallery")
            .get()
            .then(querySnapshot => {
                let items = []
                for (let index = 0; index < querySnapshot.docs.length; index++) {
                    const doc = querySnapshot.docs[index];
                    let data = doc.data()
                    let item = {
                        original: data.photo,
                        thumbnail: data.photo,
                        thumbnailHeight: 70,
                        originalHeight: 500,
                        link: data.link
                    }
                    items = items.concat(item)
                }
                console.log('models: ' + JSON.stringify(items));
                this.setState({ loader: false, models: items })
            })
            .catch(e => {
                //
            });
    };

    render() {
        return (
            <div
                style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0, width: '100%', overflow: 'scroll' }}>
                <img
                    src={f}
                    style={{ position: 'absolute', width: '100%', height: '85vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', height: '85vh', display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
                    <div
                        style={{ backgroundImage: `url(${background_strip})`, height: '24vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {this.state.loader ?
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                                <Loader
                                    type="ThreeDots"
                                    color="#20b4c6"
                                    height={70}
                                    width={70}
                                    visible={true} />
                            </div>
                            :
                            <div
                                className='gallery-img-panel-parent'>
                                <div
                                    className='gallery-img-panel'>
                                    {this.state.models.map((item, index) => {
                                        return (
                                            <div style={{ marginLeft: 15, marginRight: index == this.state.models.length - 1 ? 15 : 0, padding: 5, backgroundColor: 'white', borderRadius: 25 }}>
                                                <img
                                                    src={item.original}
                                                    className='gallery-img' />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                    <div
                        style={{ height: '60vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        <img
                            src={gallery_welcome}
                            className='gallery-txt' />

                        <div style={{ display: 'flex', width: '100%', height: '100%', flexWrap: this.state.screenWidth <= 1000 ? 'wrap' : 'nowrap', alignItems: 'stretch', justifyContent: 'center' }}>

                            <div style={{ marginLeft: 0, marginBottom: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', minHeight: 300, width: this.state.screenWidth <= 600 ? this.state.screenWidth : this.state.screenWidth / 2 }}>
                                <div style={{}}>
                                    <img
                                        src={screen2}
                                        style={{ width: 200, height: 350, objectFit: 'fill' }} />
                                </div>

                                <div 
                                    style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                    <h6 className='download-title'>Download Art Gallery App</h6>
                                    <button
                                        onClick={() => {
                                            window.location = 'https://apps.apple.com/us/app/nyeusi-gallery/id1588252724'
                                        }}
                                        style={{ borderWidth: 0, marginTop: 10, backgroundColor: 'black', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        className='download-btn'>
                                        <img
                                            src={apple}
                                            className='download-btn-apple' />
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                            <div className='download-btn-txt1'>Get it on</div>
                                            <div className='download-btn-txt2'>Apple Store</div>
                                        </div>
                                    </button>
                                    <button
                                        onClick={() => {
                                            window.location = 'https://play.google.com/store/apps/details?id=com.nyeusi.app'
                                        }}
                                        style={{ borderWidth: 0, marginTop: 15, backgroundColor: 'black', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        className='download-btn'>
                                        <img
                                            src={google}
                                            className='download-btn-google' />
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                            <div className='download-btn-txt1'>Get it on</div>
                                            <div className='download-btn-txt2'>Google Play</div>
                                        </div>
                                    </button>
                                </div>

                            </div>

                            <div style={{ marginBottom: 10, alignItems: 'center', justifyContent: 'center', display: 'flex', minHeight: 300, width: this.state.screenWidth <= 600 ? this.state.screenWidth : this.state.screenWidth / 2 }}
                                className='scroll-bottom-padding'>

                                <div 
                                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                                    className='download-section'>
                                    <h6 className='download-title'>Download Art Auction App</h6>
                                    <button
                                        onClick={() => {
                                            window.location = 'https://apps.apple.com/us/app/nyeusi/id1608856561'
                                        }}
                                        style={{ borderWidth: 0, marginTop: 10, backgroundColor: 'black', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        className='download-btn'>
                                        <img
                                            src={apple}
                                            className='download-btn-apple' />
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                            <div className='download-btn-txt1'>Get it on</div>
                                            <div className='download-btn-txt2'>Apple Store</div>
                                        </div>
                                    </button>
                                    <button
                                        onClick={() => {
                                            window.location = 'https://play.google.com/store/apps/details?id=com.nyeusi'
                                        }}
                                        style={{ borderWidth: 0, marginTop: 15, backgroundColor: 'black', borderRadius: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        className='download-btn'>
                                        <img
                                            src={google}
                                            className='download-btn-google' />
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                                            <div className='download-btn-txt1'>Get it on</div>
                                            <div className='download-btn-txt2'>Google Play</div>
                                        </div>
                                    </button>
                                </div>

                                <div style={{}}>
                                    <img
                                        src={screen1}
                                        style={{ width: 200, height: 350, objectFit: 'fill' }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ArtGallery;