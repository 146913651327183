import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/b1.png';
import forward from '../assets/forward.png';
import ImageGallery from 'react-image-gallery';

class Print extends Component {

    state = {
        models: [],
        loader: true,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        setTimeout(() => {
            this.setState({ loader: false })
        }, 500);
    };

    render() {
        return (
            <div
                className="container"
                style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
                </div>
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
                    {this.state.loader ?
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                            <Loader
                                type="ThreeDots"
                                color="#20b4c6"
                                height={70}
                                width={70}
                                visible={true} />
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', width: 500, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', marginTop: 10 }}>
                            {this.state.models.map(() => {
                                return (
                                    <button
                                        style={{ width: '100%', marginTop: 15, padding: 15, borderRadius: 10, display: 'flex', border: '0px solid white' }}
                                        className='link-item'
                                        onClick={() => {
                                            window.open('https://facebook.com', '_blank')
                                        }}>
                                        <div
                                            style={{ color: 'blue' }}>
                                            https://www.facebook.com
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginRight: 15 }}>
                                            <img
                                                src={forward}
                                                style={{ width: 25, height: 25, }} />
                                        </div>
                                    </button>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Print;