import React, { Component } from 'react';
import db from './firebase/firebase';
import bg from '../assets/design_bg.png';
import designer from '../assets/designer.png';
import btn_orange from '../assets/btn_orange.png';

class Design extends Component {

    state = {
        models: [],
        loader: true,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        this.setState({ loader: true })
        db.collection("dale_design")
            .get()
            .then(querySnapshot => {
                let items = []
                for (let index = 0; index < querySnapshot.docs.length; index++) {
                    const doc = querySnapshot.docs[index];
                    let item = doc.data()
                    items = items.concat(item)
                    // if (items.length == 3)
                    //     break
                }
                this.setState({ loader: false, models: items })
            })
            .catch(e => {
                //
            });
    };

    render() {
        return (
            <div
                style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
                <img
                    src={bg}
                    style={{ position: 'absolute', width: '100%', height: '85vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', height: '85vh', display: 'flex', alignItems: 'stretch', flexDirection: 'row' }}>
                    <div style={{ marginLeft: 10, flex: 2, display: 'flex', justifyContent: 'center' }}>
                        <div
                            className='desgin-img-panel-parent'>
                            {this.state.models.map((item, index) => {
                                return (
                                    <div
                                        className='design-img-panel'>
                                        <div style={{ marginTop: 10, marginBottom: index == this.state.models.length - 1 ? 50 : 0, padding: 5, backgroundColor: 'white', borderRadius: 10 }}>
                                            <img
                                                src={item.photo}
                                                className='desgin-img' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ flex: 7, display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                        <img
                            src={designer}
                            style={{ width: '100%', objectFit: 'contain' }} />
                        <button
                            class='button4'
                            style={{ marginTop:10, alignSelf:'flex-end', marginRight:30, backgroundColor:'#fff', color:'#cd5e0a', borderWidth:0 }}
                            onClick={() => {
                                console.log('log'); 
                                // history.replace('/contact')
                                window.location = '/contact'
                            }}>
                            Let's Create Yours
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Design;