import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import background from '../assets/b1.png';
import db, { storage } from './firebase/firebase';

const AdminDesign = () => {
  const history = useHistory()
  const [models, setModels] = useState([])
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    loadData()
  }, [])

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const loadData = () => {
    setLoader(true)
    db.collection("dale_design")
      .get()
      .then(querySnapshot => {
        let items = []
        for (let index = 0; index < querySnapshot.docs.length; index++) {
          const doc = querySnapshot.docs[index];
          let item = doc.data()
          item.id = doc.id
          items = items.concat(item)
        }
        setModels(items)
        setLoader(false)
      })
      .catch(e => {
        //
      });
  };

  const deleteItem = (item) => {
    setLoader(true)
    db.collection("dale_design")
      .doc(item.id)
      .delete()
      .then(() => {
        setLoader(false)
        loadData()
      })
      .catch(e => {
        //
      });
  };

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0, height: '90vh' }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
      </div>
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10 }}>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 0, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 50 }}
              onClick={() => {
                history.push('/admin-design-add')
              }}>
              Add Design
            </Button>

            <MyLoader loader={loader} />

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 0, height: 450, overflow: 'scroll' }}>
              {models.map((item, index) => {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 20, marginTop: 20 }}>
                    <img src={item.photo} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: 15 }}>
                      <Button
                        className='btn-block btn-dark'
                        style={{ fontSize: 14, fontWeight: 'bold', width: 100, height: 35, backgroundColor: 'red', borderWidth: 0 }}
                        onClick={() => {
                          deleteItem(item)
                        }}>
                        Delete
                      </Button>
                    </div>
                  </div>
                )
              })}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminDesign;