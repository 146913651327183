import React, { useState, useEffect } from 'react';
import '../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import MyStorage from './util/storage'
import facebook from '../assets/facebook.png';
import twitter from '../assets/twitter.png';
import linkedin from '../assets/linkedin.png';
import youtube from '../assets/youtube.png';

const Footer = ({ toggleNav }) => {
  return (
    <div className='footer'>
      <div style={{ color: 'white', fontSize: 12 }}>
        © 2022 NYEUSI - ALL RIGHTS RESERVED
      </div>
      <div
        className='footer-icons'>
        <div
          style={{ width: 25, height: 25, borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
          className='social-icon'>
          <img
            src={facebook}
            style={{ alignSelf: 'center', width: 16, height: 16 }} />
        </div>
        <div style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
          className='social-icon'>
          <img
            src={twitter}
            style={{ alignSelf: 'center', width: 16, height: 16 }} />
        </div>
        <div style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
          className='social-icon'>
          <img
            src={linkedin}
            style={{ alignSelf: 'center', width: 14, height: 14 }} />
        </div>
        <div style={{ marginLeft: 10, width: 25, height: 25, borderRadius: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
          className='social-icon'>
          <img
            src={youtube}
            style={{ alignSelf: 'center', width: 18, height: 18 }} />
        </div>
      </div>
    </div>
  );
}

export default Footer;
