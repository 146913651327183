import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/b1.png';
import forward from '../assets/forward.png';
import ImageGallery from 'react-image-gallery';

class PocDocTerms extends Component {

    state = {
        loader: true,
    }

    componentDidMount() {

    };

    render() {
        return (
            <div
                className="container"
                style={{ marginTop: 0, marginLeft: 0, marginRight: 0 }}>
                <div
                    style={{ position: 'absolute', height: '100%', width: '100%', display: 'flex', flexDirection: 'column', padding: 20, overflow: 'scroll' }}>
                    <div style={{ fontSize: 24, fontWeight: 'bold' }}>POCDOC TERMS OF USE</div>

                    POCDoc operates this POCDoc health mobile application (the " POCDoc app"). This project will be in four phases. Phase 1 will be the building of a POC physician database. Phase 2 will then allow patient access to said database .Phase 3, will be the telehealth platform of the service. The final phase, Phase 4 will be an educational piece that will share educational content of diseases that are primarily affecting the POC community. The ultimate end results of POCDoc is to create a repository of POC doctors and to get POC access to POC physicians if needed. Through the app we will offer an online physician contact service that, when clinically appropriate, lets people ask medical questions. If you request to use the service and the inquiry is considered appropriate, we will facilitate the answering to all questions. Other POCDoc services include a database of POC doctors who chose to register with the platform and also medical tips focused on education on topics that predominantly affects the black community. By downloading or using the app or website, you agree to be bound by POCDoc Terms of Use, the Consent to Service, and the Privacy Policy. If you do not wish to be bound by these Terms of Use, you may not use the App or Website.
                    <br/><br/>
                    The POCDoc app is intended for use only on a mobile phone that runs an unmodified manufacturer-approved operating system. Using the app on a mobile phone with a modified operating system may undermine security features that are intended to protect health information that ‘individually identifies’ you from unauthorized or unintended disclosure. You may compromise your health information if you use the app on a mobile phone that has been modified. Use of the app on a mobile phone with a modified operating system is a material breach of our Terms of Use. POCDoc operates within the strict HIPAA protocols established and also utilizes a HIPAA secured server.
                    <br/><br/>
                    DISCLAIMER OF WARRANTIES. You expressly agree that use of the app is at your sole risk. We provide the app and website on an “as is” and “as available” basis. POCDoc health expressly disclaims all. Limitation of liability- You understand that to the extent permitted under applicable law, in no event will POCDoc health or its officers, employees, directors, parents, subsidiaries, affiliates, agents or licensors be liable for any indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of revenues, profits, goodwill, use, data or other intangible losses arising out of or related to your use of the site of the app, regardless of whether such damages are based on contract, tort (including negligence and strict liability), warranty, statute or otherwise. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of our liability will be the minimum permitted under such applicable law.
                    <br/><br/>
                    User Accounts. When you register on the App or Website, you will choose the patient side of the app. You need to create an account by entering your name, email address, password, telephone number, in order to enter the POCDoc platform in order to utilize the POCDoc Service. You may not transfer or share your account password with anyone, or create more than one account. You are responsible for maintaining the confidentiality of your account  and your password and for all activities that occur under your account. You are responsible for changing your password promptly if you think it has been compromised. We reserve the right to take any and all action, as it deems necessary or reasonable, regarding the security of your Account. You may not use anyone else’s account at any time.
                    <br/><br/>
                    App Content. The app will eventually contain a section with medical educational content created and vetted by a boarded certified physician. This medical tip section will focus on medical problems that are dominant in the black population. The push notifications and advice we give are transmitted via a HIPAA secured server. The medical advice is a guide and does not replace you seeing you physician. POCDoc will not be responsible for any emergency requests. All emergencies and urgencies should be immediately taken to a hospital emergency department ( see list below)
                    <br/><br/>
                    List of emergencies
                    Go to the emergency department or call your local emergency number to get help with the following medical emergencies
                    Chest pain
                    Shortness of breath
                    Choking
                    Blurry vision
                    Head trauma
                    Electric shock
                    Severe burn
                    Seizures
                    Heavy Bleeding
                    Sudden weakness on one side of body
                    Facial drooping
                    Throwing up blood
                    Dizziness that is getting worse or not going away
                    High fever with headache or stiff neck
                    Severe allergic reaction ( swelling, difficulty breathing )
                    Suicidal thoughts
                    <br/><br/>
                    Access Rights. POCDoc grants you a limited, non-exclusive, non-transferable right to access the POCDoc app from a device located in the states where we operate  and to use the Service solely for your personal non-commercial use and only as permitted under these Terms of Use. We reserve the right, in our sole discretion, to deny or suspend use of the app to anyone for any reason. You agree that you will not, and will not attempt to:
                    impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity.
                    use the App to violate any local, state, national or international law
                    reverse engineer, disassemble, decompile, or translate any software or other components of the app or Website
                    distribute viruses or other harmful computer code through the app
                    otherwise use the App in any manner that exceeds the scope of use .
                    <br/><br/>
                    Ownership. The App and their entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned or used under license by POCDoc and are protected by United States and international copyright, trademark, trade secret, and other intellectual property or proprietary rights laws. These Terms of Use permit you to use the for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our App except as generally and ordinarily permitted through the App according to these Terms of Use. You must not access or use for any commercial purposes any part of the App or Website.
                    <br/><br/>
                    Trademarks. Certain names, logos, and other materials displayed on the App may constitute trademarks, trade names, service marks or logos of POCDoc Health or other entities. You are not authorized to use any such marks without the express written permission of POCDoc Health. Ownership of all such marks and the goodwill associated therewith remains with us.
                    <br/><br/>
                    Termination. POCDoc Health may suspend or terminate your use of the app for any reason at any time. Subject to applicable law, POCDoc Health reserves the right to maintain, delete or destroy all communications and materials posted or uploaded to the app pursuant to its internal record retention and/or content destruction policies. After such termination, POCDoc Health will have no further obligation to provide the Service, except  if we are required to provide you with continuing care under our applicable legal, ethical and professional obligations to you.
                    <br/><br/>
                    Right to modify. We may at our sole discretion change, add, or delete portions of these Terms of Use at any time on a going-forward basis. Continued use of the App following notice of any such changes will indicate your acknowledgement of such changes and agreement to be bound by the revised Terms of Use, inclusive of such changes.
                    <br/><br/>
                    Indemnification. You agree to indemnify, defend, and hold harmless POCDoc Health, its officers, directors, employees, agents, subsidiaries, affiliates, licensors, and suppliers, harmless from and against any claim, actions, demands, liabilities, and settlements, including, without limitation, reasonable legal and accounting fees ("Claims"), resulting from, or alleged to result from, your violation of these Terms of Use.
                    <br/><br/>
                    Geographical Restrictions. You may only use the App from a device located in the United States of America. POCDoc Health makes no representation that the App is appropriate or available for use in locations outside the USA.
                    <br/><br/>
                    Other: These Terms of Use and your use of the App shall be governed by the laws of the United States , without giving effect to the principles of conflict of laws. Any dispute arising under or relating in any way to these Terms of Use will be resolved exclusively by final and binding arbitration in the United States under the rules of the American Arbitration Association, except that either party may bring a claim related to intellectual property rights, or seek temporary and preliminary specific performance and injunctive relief, in any court of competent jurisdiction, without the posting of bond or other security. The parties agree to the personal and subject matter jurisdiction and venue of the courts located in Pennsylvania, for any action related to these Terms of Use.
                    <br/><br/>
                    You understand that by checking the “I Agree” box for these Terms of Use, the Consent to Telehealth and Privacy Policy, when they are presented to you on the App you are agreeing to these Terms of Use and the Consent to Telehealth and Privacy Policy and that such action constitutes a legal signature. No waiver by POCDoc Health of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of POCDoc Health to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
                    <br/><br/>
                    The Digital Millennium Copyright Act of 1998 (the "DMCA") provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials appearing on the App infringe your copyright, you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. In addition, if you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send us a counter-notice. Notices and counter-notices must meet statutory requirements imposed by the DMCA.
                    <br/><br/>
                </div>
            </div>
        )
    }
}

export default PocDocTerms;