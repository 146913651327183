import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { Input } from 'reactstrap';
import * as Strings from './strings'
import * as Colors from './colors'
import Api from './api/api'
import MyStorage from './util/storage';
import { Form, FormGroup, Button } from 'reactstrap';
import showToast from './util/toast'
import { ToastContainer, toast } from 'react-toastify';
import MyLoader from './util/loader'
import moment from 'moment';
import background from '../assets/b1.png';
import apple from '../assets/apple.png';
import android from '../assets/android.png';
import web from '../assets/web.png';

const Dashboard = () => {
    const history = useHistory();

    return (
        <div>
            <img
                src={background}
                style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
            <div
                style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
            </div>

            <div
                style={{ position: 'absolute', width: '100%', height: '90vh', display: 'flex', flexDirection: 'column' }}>
                <div
                    className='title text-shadow'>
                    NYEUSI
                </div>
                <div
                    className='sub-title text-shadow'>
                    Custom Web & Mobile App Development
                </div>

                <button
                    style={{ alignSelf: 'center' }}
                    class='button3'
                    onClick={() => {
                        console.log('log');
                        history.replace('/contact')
                    }}>
                    Let Us Create Your App
                </button>

                <div
                    style={{ alignSelf: 'center', marginTop: 30, display: 'flex' }}>
                    <div
                        style={{ borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
                        className='icon'>
                        <img
                            src={web}
                            className='icon-img' />
                    </div>
                    <div style={{ marginLeft: 20, borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
                        className='icon'>
                        <img
                            src={android}
                            className='icon-img' />
                    </div>
                    <div style={{ marginLeft: 20, borderRadius: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', border: '0px solid #295b6d' }}
                        className='icon'>
                        <img
                            src={apple}
                            className='icon-img' />
                    </div>
                </div>

            </div>


        </div>
    );
}

export default Dashboard;