import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Header from './cmp/header';
import Footer from './cmp/footer';
import Dashboard from './cmp/dashboard';
import Portfolio from './cmp/portfolio';
import Design from './cmp/design';
import Print from './cmp/print';
import ArtGallery from './cmp/art_gallery';
import Contact from './cmp/contact';
import Login from './cmp/login';
import AdminDashboard from './cmp/admin_dashboard';
import AdminDesign from './cmp/admin_design';
import AdminArtGallery from './cmp/admin_art_gallery';
import AdminDesignAdd from './cmp/admin_design_add';
import AdminArtGalleryAdd from './cmp/admin_art_gallery_add';
import NclexPrivacy from './cmp/nclex_privacy';
import NclexTerms from './cmp/nclex_terms';
import AbsitePrivacy from './cmp/absite_privacy';
import AbsiteTerms from './cmp/absite_terms';
import PocDocTerms from './cmp/pocdoc_terms';
import PocDocPrivacy from './cmp/pocdoc_privacy';
import CaribbeanTerms from './cmp/caribbean_terms';
import CaribbeanPrivacy from './cmp/caribbean_privacy';
import Eat4LivingPrivacy from './cmp/eat4living_privacy';
import Eat4LivingTerms from './cmp/eat4living_terms';

import { ToastContainer, toast } from 'react-toastify';

const HomeContainer = () => {

  return (
    <div style={{ display: 'flex', flexDirection: 'row', position:'fixed', height:'100%', width:'100%'}}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1,  }}>
        <Header />
        <div style={{ display: 'flex' }}>
          <Route path='/' exact component={Dashboard} />
          <Route path='/portfolio' exact component={Portfolio} />
          <Route path='/design' exact component={Design} />
          <Route path='/print' exact component={Print} />
          <Route path='/art-gallery' exact component={ArtGallery} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/login' exact component={Login} />
          <Route path='/admin-dashboard' exact component={AdminDashboard} />
          <Route path='/admin-design' exact component={AdminDesign} />
          <Route path='/admin-art-gallery' exact component={AdminArtGallery} />
          <Route path='/admin-design-add' exact component={AdminDesignAdd} />
          <Route path='/admin-art-gallery-add' exact component={AdminArtGalleryAdd} />
          <Route path='/nclex-privacy' exact component={NclexPrivacy} />
          <Route path='/nclex-terms' exact component={NclexTerms} />
          <Route path='/absite-privacy' exact component={AbsitePrivacy} />
          <Route path='/absite-terms' exact component={AbsiteTerms} />
          <Route path='/pocdoc-terms' exact component={PocDocTerms} />
          <Route path='/pocdoc-privacy' exact component={PocDocPrivacy} />
          <Route path='/caribbean-terms' exact component={CaribbeanTerms} />
          <Route path='/caribbean-privacy' exact component={CaribbeanPrivacy} />
          <Route path='/eat4living-privacy' exact component={Eat4LivingPrivacy} />
          <Route path='/eat4living-terms' exact component={Eat4LivingTerms} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route component={HomeContainer} />
          </Switch>
          <ToastContainer />
        </div>
      </Router>
    );
  }
}

export default App;
