import React, { useState, useEffect } from 'react';
import '../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import MyStorage from './util/storage'

const Header = ({ toggleNav }) => {
  var history = useHistory()

  const [selected, setSelected] = useState(0)

  return (
    <div className='header'>
      <div
        style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>

        <button
          style={{ marginLeft: 0, borderBottom: selected == 0 ? '1px solid white' : '0px solid white' }}
          class='button1'
          onClick={() => {
            console.log('log');
            setSelected(0)
            history.replace('/')
          }}>
          HOME
        </button>

        <button
          class='button1'
          style={{ borderBottom: selected == 1 ? '1px solid white' : '0px solid white' }}
          onClick={() => {
            console.log('log');
            setSelected(1)
            history.replace('/portfolio')
          }}>
          PORTFOLIO
        </button>

        {/* <button
          class='button1'
          style={{ borderBottom: selected == 2 ? '1px solid white' : '0px solid white' }}
          onClick={() => {
            console.log('log');
            setSelected(2)
            history.replace('/design')
          }}>
          DESIGN
        </button> */}

        <button
          class='button1'
          style={{ borderBottom: selected == 3 ? '1px solid white' : '0px solid white' }}
          onClick={() => {
            console.log('log');
            setSelected(0)
            // history.replace('/print')
            window.location = 'https://www.mawon.live'
          }}>
          MAWON Store
        </button>

        <button
          class='button1'
          style={{ borderBottom: selected == 4 ? '1px solid white' : '0px solid white' }}
          onClick={() => {
            console.log('log');
            setSelected(0)
            // history.replace('/art-gallery')
            window.location = 'https://www.nyeusi.art'
          }}>
          ART GALLERY
        </button>


        <button
          class='button2'
          onClick={() => {
            console.log('log');
            setSelected(5)
            history.replace('/contact')
          }}>
          Contact Us
        </button>

      </div>

    </div>
  );
}

export default Header;
