import React, { Component } from 'react';
import Slider from 'react-slick';
import db, { storage } from './firebase/firebase';
import Loader from 'react-loader-spinner'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { getMobileView } from './util/mobile_view';
import background from '../assets/b1.png';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "gray", borderRadius: 50 }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "flex", background: "gray", borderRadius: 50 }}
            onClick={onClick}
        />
    );
}

class Portfolio extends Component {

    state = {
        portType: 'iOS',
        models: [],
        photos: [],
        loader: true,
    }

    componentDidMount() {
        this.loadData();
    }

    getImageUrl = (path, onSuccess) => {
        storage.ref().child(path).getDownloadURL().then((url) => {
            onSuccess(url);
        })
    }

    loadData = () => {
        this.setState({ loader: true });
        db.collection(this.state.portType)
            .orderBy('order', 'asc')
            .get()
            .then(querySnapshot => {
                const projects = querySnapshot.docs.map(doc => doc.data());
                console.log('size: ' + projects.length);
                let models = [];
                let photos = [];
                for (let i = 0; i < projects.length; i++) {
                    const project = projects[i];
                    console.log('cat: ' + project.cat);
                    models = models.concat(project);
                    photos.push(''); // dummy fill
                    // update dummy fill download urls
                    this.getImageUrl(
                        project.cat + '/' + project.title + '/' + project.mainShot,
                        (url) => {
                            console.log('url: ' + url);
                            photos[i] = url;
                            this.setState({ photos: photos })
                            // setPhotos([...photos], photos);
                        });
                }
                this.setState({ models: models, photos: photos, loader: false })
                // setModels([...models], models);
                // setPhotos(photos);
            });
    };

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            autoplay: true,
            pauseOnHover: true,
            autoplaySpeed: 3000,
            slidesToShow: this.state.portType === 'Website' ? 1 : 4,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: this.state.portType === 'Website' ? 1 : 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <div
                style={{ backgroundColor: 'white', marginTop: 10, marginLeft: 0, marginRight: 0, padding: 0 }}>
                {/* <img
                    src={background}
                    style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
                <div
                    style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
                </div> */}
                <div
                    style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {/* <h3>Portfolio</h3>
                    <h6>Some of our works</h6> */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, marginBottom: 15 }}>
                        <div class="btn-group" role="group" aria-label="..." style={{ border: '0px solid green', borderRadius: 5 }}>
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'Website' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'Website' ? "btn btn-success" : "btn btn-light"}
                                style={{ width: 150, height: 40, fontSize: 16 }}>
                                Web
                            </button>
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'iOS' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'iOS' ? "btn btn-success" : "btn btn-light"}
                                style={{ width: 150, height: 40, fontSize: 16 }}>
                                iOS
                            </button>
                            <button
                                onClick={() => {
                                    this.setState({ portType: 'Android' });
                                    setTimeout(() => {
                                        this.loadData();
                                    }, 50);
                                }}
                                type="button"
                                class={this.state.portType === 'Android' ? "btn btn-success" : "btn btn-light"}
                                style={{ width: 150, height: 40, fontSize: 16 }}>
                                Android
                            </button>
                        </div>
                    </div>

                    <section className="portfolio_slider_area" style={{ paddingTop: 0, paddingBottom: 0 }} id="portfolio">
                        <div className="container">
                            {this.state.loader ?
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
                                    <Loader
                                        type="ThreeDots"
                                        color="#20b4c6"
                                        height={70}
                                        width={70}
                                        visible={true} />
                                </div>
                                :
                                <Slider {...settings} onClick={() => { }}>
                                    {
                                        this.state.models.map((project, index) => {
                                            return (
                                                <div>
                                                    <Fade bottom cascade duration={500}>
                                                        <div className="p_item">
                                                            <div className="portfolio_content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {getMobileView(this.state.photos[index], project.cat)}
                                                            </div>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            )
                                        })
                                    }
                                </Slider>
                            }
                        </div>
                    </section>

                </div>
            </div>
        )
    }
}

export default Portfolio;