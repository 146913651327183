import React, { useState, useEffect } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import background from '../assets/b1.png';

const AdminDashboard = () => {
  const history = useHistory()

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
      </div>
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10 }}>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 0, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-design')
              }}>
              Design
            </Button>
            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height:50 }}
              onClick={() => {
                history.push('/admin-art-gallery')
              }}>
              Art Gallery
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;