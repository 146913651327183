import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/auth'
const firebaseApp = firebase.initializeApp({
  // apiKey: "AIzaSyAqAxi6iRyHm0zy55HIsJHRbGOU9mBXMnc",
  // authDomain: "nyeusi-63273.firebaseapp.com",
  // databaseURL: "",
  // projectId: "nyeusi-63273",
  // storageBucket: "gs://nyeusi-63273.appspot.com",
  // messagingSenderId: "976604719317"
  apiKey: "AIzaSyARLHBSGw-PqDinM5IAcwy6QWhiIKbztEA",
  authDomain: "",
  databaseURL: "",
  projectId: "adminapp-31c2d",
  storageBucket: "gs://adminapp-31c2d.appspot.com",
  messagingSenderId: "178182885449"
});
const db = firebaseApp.firestore();
export const storage = firebase.storage()
export const auth = firebase.auth()
export default db;