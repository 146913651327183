import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { Link, useHistory } from 'react-router-dom';
import * as Colors from './colors'
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import MyLoader from './util/loader'
import MyError from './util/error'
import MyStorage from './util/storage'
import Api from './api/api'
import background from '../assets/b1.png';
import db, { storage } from './firebase/firebase';

const AdminDesignAdd = () => {
  const history = useHistory()
  const [link, setLink] = useState('');
  const [mainShot, setMainShot] = useState(null);
  const [mainShotUrl, setMainShotUrl] = useState(null);
  const [mainShotName, setMainShotName] = useState(null);
  const [loader, setLoader] = useState(false);
  const addMainShot = useRef(null);

  if (!MyStorage.isLogin()) {
    console.log('not logged in');
    history.replace('/login')
    return null
  }

  const addDesginNow = () => {
    if (mainShot == null) {
      return
    }

    setLoader(true)
    console.log('mainShotName: ' + mainShotName);
    const uploadTask = storage.ref('dale/design/' + mainShotName).put(mainShot);
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        console.log('progress: ' + progress);
      },
      (e) => {
        console.log(e);
      },
      () => {
        // success
        storage.ref().child('dale/design/' + mainShotName).getDownloadURL().then((url) => {
          console.log('url: ' + url);
          db.collection('dale_design')
            .add(
              {
                photo: url,
                link: link
              }
            )
            .then(() => {
              setLoader(false)
              history.goBack() 
            })
            .catch(e => {
              setLoader(false)
            });
        })
      });
  };

  return (
    <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, padding: 0 }}>
      <img
        src={background}
        style={{ position: 'absolute', width: '100%', height: '90vh', objectFit: 'cover' }} />
      <div
        style={{ position: 'absolute', width: '100%', height: '90vh', backgroundColor: '#295b6d', opacity: 0.6 }}>
      </div>
      <div
        style={{ position: 'absolute', width: '100%', display: 'flex', flexDirection: 'column', padding: 0 }}>
        <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Form className='form' style={{ padding: 20, backgroundColor: 'white', borderRadius: 10 }}>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={mainShotUrl}
                style={{ width: 200, height: 150, marginBottom: 10, marginTop: 20, objectFit: 'contain' }} />
              <Button
                style={{ marginTop: 0, fontSize: 16 }}
                className='btn-block'
                onClick={() => {
                  addMainShot.current.click();
                }}>
                Choose Image
              </Button>
              <FormGroup style={{ marginTop: 20, width: '100%' }}>
                <Input
                  style={{ fontSize: 18 }}
                  type='text'
                  placeholder='Link (optional)'
                  value={link}
                  onChange={(event) => {
                    console.log('change: ' + event.target.value);
                    setLink(event.target.value)
                  }} />
              </FormGroup>
            </div>

            <input
              type='file'
              style={{ display: 'none' }}
              ref={addMainShot}
              onChange={(event) => {
                setMainShot(event.target.files[0]);
                setMainShotUrl(URL.createObjectURL(event.target.files[0]))
                setMainShotName(event.target.files[0].name)
              }} />

            <MyLoader loader={loader} />

            <Button
              className='btn-block btn-dark'
              style={{ marginTop: 15, fontSize: 18, fontWeight: 'bold', width: '100%', backgroundColor: 'orange', borderWidth: 0, height: 50 }}
              onClick={() => {
                addDesginNow()
              }}>
              Add Desgin
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AdminDesignAdd;